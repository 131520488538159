import React, { Fragment } from 'react';
import { useRouteMatch } from 'react-router-dom';

import can from 'helpers/can';
import { useOrganization } from 'helpers/hooks';
import { pathToHome } from 'helpers/paths';

import { Redirect, Route, Switch } from 'components';

import Catalog from './Catalog';

export default function Routes() {
  const { path } = useRouteMatch();
  const organization = useOrganization();
  const canShowTrainingCatalog = can({
    perform: 'show_training_catalog',
    on: organization,
  });

  return (
    <Switch>
      {canShowTrainingCatalog && (
        <Fragment>
          <Route path={`${path}/catalog`} exact component={Catalog} />

          <Redirect to={`${path}/catalog`} />
        </Fragment>
      )}

      <Redirect to={pathToHome()} />
    </Switch>
  );
}
